export const contestQuery = {
    "contest.start": {
        "type": "condition",
        "mustMatchAll": true,
        "mustEvaluateTo": true,
        "scope": "",
        "operand": "",
        "operator": "",
        "value": "",
        "rules": [],
        "then": [
            {
                "routine": "",
                "arguments": [],
                "lineNumber": 2
            }
        ],
        "lineNumber": 1
    },
    "contest.finish": {
        "type": "condition",
        "mustMatchAll": true,
        "mustEvaluateTo": true,
        "scope": "",
        "operand": "",
        "operator": "",
        "value": "",
        "rules": [],
        "then": [
            {
                "routine": "",
                "arguments": [],
                "lineNumber": 2
            }
        ],
        "lineNumber": 1
    },
    "contest.finalise": {
        "type": "condition",
        "mustMatchAll": true,
        "mustEvaluateTo": true,
        "scope": "",
        "operand": "",
        "operator": "",
        "value": "",
        "rules": [],
        "then": [
            {
                "routine": "",
                "arguments": [],
                "lineNumber": 2
            }
        ],
        "lineNumber": 1
    },
    "contest.cancel": {
        "type": "condition",
        "mustMatchAll": true,
        "mustEvaluateTo": true,
        "scope": "",
        "operand": "",
        "operator": "",
        "value": "",
        "rules": [],
        "then": [
            {
                "routine": "",
                "arguments": [],
                "lineNumber": 2
            }
        ],
        "lineNumber": 1
    },
    "contest.entrant": {
        "type": "condition",
        "mustMatchAll": true,
        "mustEvaluateTo": true,
        "scope": "",
        "operand": "",
        "operator": "",
        "value": "",
        "rules": [],
        "then": [
            {
                "routine": "",
                "arguments": [],
                "lineNumber": 2
            }
        ],
        "lineNumber": 1
    },
    "contest.points": {
        "type": "condition",
        "mustMatchAll": true,
        "mustEvaluateTo": true,
        "scope": "",
        "operand": "",
        "operator": "",
        "value": "",
        "rules": [],
        "then": [
            {
                "routine": "",
                "arguments": [],
                "lineNumber": 2
            }
        ],
        "lineNumber": 1
    },
}
