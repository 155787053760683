var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"entrants-detail"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq--wizard-card"},[_c('CCardHeader',{on:{"click":function($event){_vm.basicIsCollapsed = !_vm.basicIsCollapsed}}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"title"},[_vm._v(_vm._s(_vm.texts.summary.entrants.title))]),_c('CLink',{staticClass:"card-header-action btn-minimize"},[_c('ClCardArrow',{attrs:{"is-collapsed":_vm.basicIsCollapsed}})],1)],1)]),_c('CCollapse',{attrs:{"show":_vm.basicIsCollapsed,"duration":400}},[_c('CCardBody',[_c('ClCardRow',{attrs:{"label":_vm.texts.summary.entrants.optLabel,"content":("" + (_vm.data.optinRequiredForEntrants))}}),(_vm.data.limitParticipants)?_c('ClCardRow',{attrs:{"label":_vm.texts.summary.entrants.everyLabel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('RangeSlider',{attrs:{"valueProp":[_vm.data.formData.minNumberOfEntrants, _vm.data.formData.maxNumberOfEntrants],"disabled":true}})]},proxy:true}],null,false,442554853)}):_vm._e(),(_vm.data.formData.dependantOn.should.length >= 2)?_c('ClCardRow',{attrs:{"label":_vm.texts.summary.entrants.shouldMatchLabel,"content":_vm.data.formData.shouldMatchAtLeast}}):_vm._e(),(_vm.entrantsData.selectedData.length)?_c('CDataTable',{staticClass:"zq--table zq--table--default-pagination",attrs:{"id":"achTableResult","items":_vm.entrantsData.selectedData,"fields":_vm.fields,"items-per-page":_vm.itemsPerPage,"sorter":{ external: true },"pagination":"","responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.created)))])]}},(_vm.isPreview)?{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{name: "PreviewMember", params: {id: item.id} }}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}:null,{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})])]}},{key:"dependency",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"action-buttons-list"},[_c('button',{staticClass:"action-buttons action-buttons__must-not",class:{'action-buttons--active__must-not': _vm.data.formData.dependantOn.mustNot.includes(item.id)}},[_vm._v(" "+_vm._s(_vm.texts.summary.entrants.mustNotText)+" ")]),_c('button',{staticClass:"action-buttons action-buttons__should",class:{'action-buttons--active__should': _vm.data.formData.dependantOn.should.includes(item.id)}},[_vm._v(" "+_vm._s(_vm.texts.summary.entrants.shouldText)+" ")]),_c('button',{staticClass:"action-buttons action-buttons__must",class:{'action-buttons--active__must': _vm.data.formData.dependantOn.must.includes(item.id)}},[_vm._v(" "+_vm._s(_vm.texts.summary.entrants.mustText)+" ")])])])])]}}],null,true)}):_vm._e(),(!_vm.entrantsData.selectedData.length)?_c('ClCardRow',{attrs:{"label":_vm.texts.summary.entrants.memberGroupsLabel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('strong',{staticClass:"group__colored"},[_vm._v(_vm._s(_vm.texts.summary.entrants.everyoneText))])]},proxy:true}],null,false,3183333493)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }